import React from "react";
import { createContext, useState } from "react";

export const BusinessContext = createContext();

function BusinessProvider({ children }) {
  const [open, setOpen] = useState(false);
  return (
    <BusinessContext.Provider value={{ open, setOpen }}>
      {children}
    </BusinessContext.Provider>
  );
}

export default BusinessProvider;
