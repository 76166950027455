import React from 'react';
import Typography from '../Typography';
type Hotel = {
  src: string;
  name: string;
  location: string;
  amenties: string;
};

function HotelsList({ hotels }: { hotels: Hotel[] }) {
  return (
    <div className="relative grid items-center justify-center grid-cols-1 gap-2 pb-2 overflow-x-auto md:grid-cols-2 lg:grid-cols-3 no-scrollbar snap-x">
      {hotels.map((img, index) => (
        <div key={index}>
          <img src={img.src} alt={img.location} className="w-full" />
          <Typography variant="bodySmall" className="mt-2 mb-5 text-left">
            <div>
              <strong className="text-black">{img.name}</strong>
            </div>
            <div>{img.location}</div>
            <div>
              <small>{img.amenties}</small>
            </div>
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default HotelsList;
