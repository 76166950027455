export const APPLE_STORE_URL = 'https://apps.apple.com/us/app/ooofer/id1622884397';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.ooofer.app';

export function getMobileOS (){
  if(typeof window !== "undefined") {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel')){
      return "iOS"
    }
    return "Other"
  }
  return 'Other'
}

export function storeUrl() {
  return getMobileOS() === "iOS" ? APPLE_STORE_URL : PLAY_STORE_URL;
}

export function redirectToStore() {
  if(typeof window !== "undefined") {
    if (getMobileOS() === "iOS") {
      window.location.replace(APPLE_STORE_URL);
    } else {
      window.location.replace(PLAY_STORE_URL);
    } 
  }
}