import React from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NewsletterSwitch({ newsLetter, setNewsLetter }) {
  return (
    <Switch.Group as="div" className="flex items-center justify-between py-2">
      <Switch.Label as="p" className="body-2 sm:mr-4 sm:shrink-0">
        I would like to receive updates, newsletters and benefits from ooofer
      </Switch.Label>
      <Switch
        checked={newsLetter}
        onChange={setNewsLetter}
        className={classNames(
          newsLetter ? "bg-black" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            newsLetter ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
}

export default NewsletterSwitch;
