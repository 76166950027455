import React from "react";

function FormTextArea({ name, label, required = true }) {
  return (
    <div className="flex gap-2 mt-2">
      <label htmlFor={name} className="subtitle-1 font-bold shrink-0">
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        rows={4}
        required={required}
        className="input-text border"
      />
    </div>
  );
}

export default FormTextArea;
