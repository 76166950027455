import React, { useState } from "react";
import Typography from "components/Typography";
import AppButton from "components/AppButton";

function CookieBanner(props) {
  const [visible, setVisible] = useState(true);

  if (!visible) {
    return null;
  }
  return (
    <div className="sticky bottom-0 z-10 bg-black">
      <div className="flex gap-4 justify-center items-center py-3 max-w-5xl mx-auto px-4 sm:px-0">
        <Typography variant="bodySmall" className="text-white">
          We use cookies to ensure that we give you the best experience on our
          website. If you continue to use this site, we will assume that you are
          happy with it.
        </Typography>
        <AppButton
          onClick={() => setVisible(false)}
          background="white"
          title="Understood"
        />
      </div>
    </div>
  );
}

export default CookieBanner;
