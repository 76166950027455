import React from "react";

function Footer(props) {
  return (
    <div className="px-4 py-8 sm:px-0 bg-stone-50">
      <div className="max-w-4xl mx-auto text-sm font-light text-left text-gray-500">
        <p className="py-2">
          Prices and information mentioned on this website are non-contractual
          and are subject to change without notice. For more information, reach
          out at info[at]ooofer.com. 7 day free trial for new subscribers only. 48.99€ or 58.99€ after free trial. Plans automatically renew until cancelled. Terms apply.
        </p>
        <p className="py-2">
        Google Play and the Google Play logo are trademarks of Google LLC.
        </p>
        <div className="flex flex-col gap-2 py-2 border-t border-gray-300 sm:flex-row">
          <p>Copyright ©2022 ooofer Inc. All rights reserved.</p>
          <div className="flex gap-2">
            <a
              href="/privacy"
              target="_blank"
              className="pr-2 text-sm text-left text-gray-900 border-r border-gray-400"
            >
              Privacy
            </a>
            <a
              href="/terms"
              target="_blank"
              className="pr-2 text-sm text-left text-gray-900 border-r border-gray-400"
            >
              Terms and Conditions
            </a>
            <a
              href="/data-deletion"
              target="_blank"
              className="pr-2 text-sm text-left text-gray-900 border-r border-gray-400"
            >
              Data deletion
            </a>
            <a
              href="/cookie"
              target="_blank"
              className="text-sm text-left text-gray-900"
            >
              Policy Cookies
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
