import img1 from '../images/hotels/hotel-0.jpg';
import img2 from '../images/hotels/hotel-1.jpg';
import img3 from '../images/hotels/hotel-2.jpg';
import img4 from '../images/hotels/hotel-3.jpg';
import img5 from '../images/hotels/hotel-4.jpg';
import img6 from '../images/hotels/hotel-5.jpg';
import img7 from '../images/hotels/hotel-6.jpg';
import img8 from '../images/hotels/hotel-7.jpg';
import img9 from '../images/hotels/hotel-8.jpg';
import img10 from '../images/hotels/hotel-9.jpg';
import img11 from '../images/hotels/hotel-10.jpg';
import img12 from '../images/hotels/hotel-11.jpg';
import img13 from '../images/hotels/hotel-12.jpg';
import img14 from '../images/hotels/hotel-13.jpg';
import img15 from '../images/hotels/hotel-14.jpg';
import img16 from '../images/hotels/hotel-15.jpg';
import img17 from '../images/hotels/hotel-16.jpg';
import img18 from '../images/hotels/hotel-17.jpg';
import img19 from '../images/hotels/hotel-18.jpg';
import img20 from '../images/hotels/hotel-19.jpg';
import img21 from '../images/hotels/hotel-20.jpg';
import img22 from '../images/hotels/hotel-21.jpg';
import img23 from '../images/hotels/hotel-22.jpg';
import img24 from '../images/hotels/hotel-23.jpg';
import img25 from '../images/hotels/hotel-24.jpg';
import img26 from '../images/hotels/hotel-25.jpg';
import img27 from '../images/hotels/hotel-26.jpg';

export const hotels = [
  {
    src: img1,
    name: 'YUST Antwerp',
    location: 'Antwerp, Belgium',
    amenties: '98 bedrooms • 5 coworking seats • 4 meeting rooms',
  },
  {
    src: img6,
    name: 'The Hotel',
    location: 'Brussels, Belgium',
    amenties: '403 bedrooms • 5 coworking seats • 6 meeting rooms',
  },
  {
    src: img11,
    name: 'Pillows Grand Reylof',
    location: 'Ghent, Belgium',
    amenties: '156 bedrooms • 25 coworking seats • 3 meeting rooms',
  },
  {
    src: img2,
    name: 'A-Stay',
    location: 'Antwerp, Belgium',
    amenties: '192 bedrooms • 20 coworking seats • 1 meeting room',
  },
  {
    src: img7,
    name: 'YUST Liège',
    location: 'Liège, Belgium',
    amenties: '100 bedrooms • 5 coworking seats • 3 meeting rooms',
  },
  {
    src: img12,
    name: 'Hotel Indigo ',
    location: 'Brussels, Belgium',
    amenties: '284 bedrooms • 10 coworking seats • 2 meeting rooms',
  },
  {
    src: img3,
    name: 'Hôtel FRANQ',
    location: 'Antwerp, Belgium',
    amenties: '67 bedrooms • 2 business lounges • 3 meeting rooms',
  },
  {
    src: img8,
    name: 'Château de Vignée',
    location: 'Rochefort, Belgium',
    amenties: '24 bedrooms • 1 business lounge • 3 meeting rooms',
  },
  {
    src: img13,
    name: '1898 The Post',
    location: 'Ghent, Belgium',
    amenties: '156 bedrooms • 1 business lounge • 1 meeting room',
  },
  {
    src: img4,
    name: 'Cove Centrum',
    location: 'The Hague, Netherlands',
    amenties: '192 bedrooms • 20 coworking seats • 1 meeting room',
  },
  {
    src: img9,
    name: 'MOB House',
    location: 'Paris, France',
    amenties: '100 bedrooms • 8 coworking seats • 1 meeting room',
  },
  {
    src: img14,
    name: 'Hôtel AMI',
    location: 'Paris, France',
    amenties: '31 bedrooms • 8 coworking seats • 0 meeting room',
  },
  {
    src: img5,
    name: 'Hôtel Cabane',
    location: 'Paris, France',
    amenties: '44 bedrooms • 5 coworking seats • 0 meeting room',
  },
  {
    src: img10,
    name: 'Hôtel Wallace',
    location: 'Paris, France',
    amenties: '45 bedrooms • 1 business lounge • 1 meeting room',
  },
  {
    src: img15,
    name: 'Hôtel Orphée',
    location: 'Paris, France',
    amenties: '44 bedrooms • 5 coworking seats • 0 meeting room',
  },
  {
    src: img16,
    name: 'Hôtel Rochechouart',
    location: 'Paris, France',
    amenties: '106 bedrooms • 1 business lounge • 1 meeting room',
  },
  {
    src: img20,
    name: 'PLEY Hôtel',
    location: 'Paris, France',
    amenties: '100 bedrooms • 5 coworking seats • 6 meeting rooms',
  },
  {
    src: img24,
    name: 'Landmark Pinnacle',
    location: 'London, United Kingdom',
    amenties: '162 bedrooms • 5 coworking seats • 1 meeting room',
  },
  {
    src: img17,
    name: 'Buckle Street Studios',
    location: 'London, United Kingdom',
    amenties: '103 bedrooms • 5 coworking seats • 1 meeting room',
  },
  {
    src: img21,
    name: 'Turing Locke',
    location: 'Cambridge, United Kingdom',
    amenties: '180 bedrooms • 8 coworking seats • 2 meeting rooms',
  },
  {
    src: img25,
    name: 'Whitworth Locke',
    location: 'Manchester, United Kingdom',
    amenties: '160 bedrooms • 10 coworking seats • 1 meeting room',
  },
  {
    src: img18,
    name: 'Eden Locke',
    location: 'Edinburgh, United Kingdom',
    amenties: '72 bedrooms • 1 business lounge • 0 meeting room',
  },
  {
    src: img22,
    name: 'Beckett Locke',
    location: 'Dublin, Ireland',
    amenties: '241 bedrooms • 10 coworking seats • 2 meeting rooms',
  },
  {
    src: img26,
    name: 'Hotel Berlin, Berlin',
    location: 'Berlin, Germany',
    amenties: '701 bedrooms • 15 coworking seats • 5 meeting rooms',
  },
  {
    src: img19,
    name: 'Libertina Lindenberg',
    location: 'Frankfurt, Germany',
    amenties: '47 bedrooms • 1 business lounge • 2 meeting rooms',
  },
  {
    src: img23,
    name: 'Roomers Hotel',
    location: 'Munich, Germany',
    amenties: '241 bedrooms • 10 coworking seats • 2 meeting rooms',
  },
  {
    src: img27,
    name: 'Lindley Lindenberg',
    location: 'Frankfurt, Germany',
    amenties: '100 bedrooms • 10 coworking seats • 3 meeting rooms ',
  },
];
