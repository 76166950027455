import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta }) => {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    return (<>
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={defaultTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: defaultTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                }
            ].concat(meta)}
        >
            <meta name="facebook-domain-verification" content="5ko0l567einat1hpd349n3w1lo0xc2" />
            <script type="text/javascript">
                {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '2381094282029279');
                fbq('init', '569715984706895');
                fbq('track', 'PageView');`}
            </script>
            <noscript>
                {`
                    <img height="1" width="1" style="display:none"
                        src="https://www.facebook.com/tr?id=2381094282029279&ev=PageView&noscript=1"
                    />
                `}
            </noscript>
            <script type="text/javascript">
                {`
            _linkedin_partner_id = "4898865";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            </script><script type="text/javascript">
            (function(l) {
                if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);
                `}
            </script>
            <noscript>
                {`
                    <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4898865&fmt=gif" />
                `}
            </noscript>
            <script async src="https://cdn.splitbee.io/sb.js" ></script>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-HG7GE71F18"></script>
            <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'G-HG7GE71F18');
            `}
            </script>
            <script>
            {`
            (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('kn9Eq4RykZl7RlvP');
            `}
            </script>
        </Helmet>
    </>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

export default Seo