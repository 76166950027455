import React from "react";
import { createContext, useState } from "react";

export const HotelContext = createContext();

function HotelProvider({ children }) {
  const [open, setOpen] = useState(false);
  return (
    <HotelContext.Provider value={{ open, setOpen }}>
      {children}
    </HotelContext.Provider>
  );
}

export default HotelProvider;
