import React from "react";

function FormTextField({
  name,
  label,
  type = "text",
  required = true,
  ...other
}) {
  return (
    <div className="flex gap-2 items-end">
      {type !== "hidden" && (
        <label htmlFor={name} className="subtitle-1 font-bold shrink-0">
          {label}
        </label>
      )}
      <input
        id={name}
        name={name}
        type={type}
        autoComplete={type === "email" ? "email" : "none"}
        required={required}
        className="input-text"
        {...other}
      />
    </div>
  );
}

export default FormTextField;
