import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

function AppButton({
  title,
  size = "xs",
  background,
  as = "button",
  ...other
}) {
  let classNames = ["rounded-3xl inline-block  whitespace-nowrap"];

  switch (size) {
    case "sm":
      classNames.push("text-sm", "px-5", "py-3", "font-medium");
      break;
    case "base":
      classNames.push("text-base", "px-8", "py-2", "font-bold");
      break;
    default:
      classNames.push("text-xs px-4 py-1");
  }

  switch (background) {
    case "black":
      classNames.push("bg-black text-white");
      break;
    case "outline":
      classNames.push("border-2 border-white text-white");
      break;
    case "white":
      classNames.push("border-2 border-white bg-white text-gray-800");
      break;
    default:
      classNames.push("px-0 text-gray-800");
  }

  if (as === "button") {
    return (
      <button className={classNames.join(" ")} {...other}>
        {title}
      </button>
    );
  } else {
    return (
      <AnchorLink title={title} className={classNames.join(" ")} {...other}>
        {title}
      </AnchorLink>
    );
  }
}

export default AppButton;
