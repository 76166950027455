import React from "react";
import { createContext, useState } from "react";

export const WaitingListContext = createContext();

function WaitingListProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(""); // local, global, premium
  return (
    <WaitingListContext.Provider
      value={{ open, setOpen, subscriptionType, setSubscriptionType }}
    >
      {children}
    </WaitingListContext.Provider>
  );
}

export default WaitingListProvider;
