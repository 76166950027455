import React, { useState, useContext } from "react";
import ModalShell from "./ModalShell";
import Typography from "components/Typography";
import FormTextField from "components/forms/FormTextField";
import SubmitButton from "components/forms/SubmitButton";
import LegalTextForFooter from "components/forms/LegalTextForFooter";
import { BusinessContext } from "contexts/BusinessProvider";
import NewsletterSwitch from "../NewsletterSwitch";
import FormTextArea from "components/forms/FormTextArea";

function BusinessModal(props) {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newsLetter, setNewsLetter] = useState(false);
  const { open, setOpen } = useContext(BusinessContext);

  function closeModal() {
    setOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData(e.target);
    const url = "https://auynb6bue8.execute-api.eu-central-1.amazonaws.com/";

    try {
      await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(Object.fromEntries(formData.entries())),
      });
      setSuccess(true);
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setSubmitting(false);
    }
  };

  const formBody = (
    <>
      <Typography variant="h3" className="text-center mt-6 sm:mt-2">
        Need ooofer for your team, employees, or <br />
        customers? Reach out.
      </Typography>
      <form onSubmit={handleSubmit} className="mt-4 w-full">
        <div className="flex flex-col gap-4">
          <FormTextField label="First name*" name="firstName" />
          <FormTextField label="Last name*" name="lastName" />
          <FormTextField label="Email address*" name="email" type="email" />
          <FormTextField label="Company" name="companyName" required={false} />
          <FormTextArea label="Message*" name="message" />
          <NewsletterSwitch
            newsLetter={newsLetter}
            setNewsLetter={setNewsLetter}
          />
          <FormTextField
            label="Newsletter"
            type="hidden"
            name="newsletter"
            value={newsLetter ? "Yes" : "Nope"}
          />
        </div>
        <SubmitButton title="Reach out" submitting={submitting} />
        <LegalTextForFooter />
      </form>
    </>
  );

  const successMsg = (
    <div className="py-24 px-4 sm:px-6 lg:px-8 text-center">
      <Typography variant="h4">Thank you for reaching out.</Typography>
      <p>
        Our team is looking into your request and will get back in touch very
        soon.
      </p>
    </div>
  );

  return (
    <ModalShell open={open} closeModal={closeModal}>
      {success ? successMsg : formBody}
    </ModalShell>
  );
}

export default BusinessModal;
