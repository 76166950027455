import React, { useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import AppButton from "../components/AppButton";
import AppLogo from "components/AppLogo";
import { BusinessContext } from "../contexts/BusinessProvider";
import { HotelContext } from "../contexts/HotelProvider";
import { DownloadButton } from "../components/DownloadButton";

export default function Navbar() {
  const { setOpen: setBusinessOpen } = useContext(BusinessContext);
  const { setOpen: setHotelOpen } = useContext(HotelContext);
  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
        <div className="w-full h-[124px]"></div>
        <div className="fixed left-0 right-0 z-10 bg-white top-10">
          <header className="w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex items-center justify-between p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <AppButton
                    as="a"
                    to="/"
                    size="sm"
                    title={<AppLogo className="text-lg" />}
                  />
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center gap-6">
                  <AppButton
                    as="a"
                    to="/#overview"
                    size="sm"
                    title="Overview"
                  />

                  <AppButton
                    to="/hotels"
                    title="Our locations"
                    as="a"
                    size="sm"
                  />

                  <AppButton
                    title="I'm a company"
                    as="a"
                    size="sm"
                    to="/corporate"
                  />
                  <AppButton
                    title="I'm a hotel"
                    as="button"
                    size="sm"
                    onClick={() => {
                      setHotelOpen(true);
                    }}
                  />

                  {/* <<AppButton
                    to="/#subscriptions"
                    as="a"
                    background="black"
                    title="Download now"
                  />> */}
                  <DownloadButton size="sm"/>
                </div>
              </div>
              <div className="flex -mr-2 sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-black rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </header>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Disclosure.Button
                as="a"
                href="/#overview"
                className="block px-3 py-2 text-base font-medium text-center text-gray-800 rounded-md hover:bg-gray-500 hover:text-white"
              >
                Overview
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/hotels"
                className="block px-3 py-2 text-base font-medium text-center text-gray-800 rounded-md hover:bg-gray-500 hover:text-white"
              >
                Our locations
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                className="block w-full px-3 py-2 text-base font-medium text-center text-gray-800 rounded-md hover:bg-gray-500 hover:text-white"
                href="/corporate"
              >
                I'm a company
              </Disclosure.Button>
              <Disclosure.Button
                as="button"
                className="block w-full px-3 py-2 text-base font-medium text-gray-800 rounded-md hover:bg-gray-500 hover:text-white"
                onClick={() => {
                  setHotelOpen(true);
                }}
              >
                I'm a hotel
              </Disclosure.Button>
              <DownloadButton className="block w-full px-3 py-2"/>
              {/* <Disclosure.Button
                as="button"
                className="block w-full px-3 py-2 text-base font-medium text-white bg-black rounded-md hover:bg-gray-500 hover:text-white"
                onClick={() => {
                  setWaitingListOpen(true);
                }}
              >
                Waiting list
              </Disclosure.Button> */}
            </div>
          </Disclosure.Panel>

        </div>
        </>
      )}
    </Disclosure>
  );
}
