import React from "react";
import AppButton from "../AppButton";

function SubmitButton({ submitting, title }) {
  return (
    <div className="mt-4 text-center">
      <AppButton
        type="submit"
        background="black"
        size="sm"
        disabled={submitting}
        title={submitting ? "Submitting" : <span>{title}</span>}
      />
    </div>
  );
}

export default SubmitButton;
