import React from "react";
import Typography from "components/Typography";

function LegalTextForFooter(props) {
  return (
    <Typography
      variant="body2"
      className="max-w-lg mx-auto mt-4 text-center text-gray-400"
    >
      By signing up, you agree to the ooofer{" "}
      <a href="/privacy" target="_blank">
        privacy policy and terms and conditions
      </a>
      . You can change the way we contact you or unsubscribe at any time.
    </Typography>
  );
}

export default LegalTextForFooter;
