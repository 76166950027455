import React from "react";
import linkedIn from "../images/socialMedia/linkedIn.svg";
import facebook from "../images/socialMedia/facebook.svg";
import instagram from "../images/socialMedia/instagram.svg";

function SocialMedia() {
  return (
    <div className="fixed top-0 z-10 flex justify-end w-full h-10 p-3 px-5 bg-black gap-7">
      <a
        href="https://instagram.com/ooofer.co?igshid=YmMyMTA2M2Y="
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagram} alt="Instagram" className="h-5" />
      </a>
      <a
        href="https://www.facebook.com/ooofer.co/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={facebook} alt="Facebook" className="h-5" />
      </a>
      <a
        href="https://www.linkedin.com/company/ooofer/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedIn} alt="LinkedIn" className="h-5" />
      </a>
    </div>
  );
}

export default SocialMedia;
