import React, { useState } from "react";
import { useContext } from "react";
import { WaitingListContext } from "../../contexts/WaitingListProvider";
import Typography from "../Typography";
import NewsletterSwitch from "../NewsletterSwitch";
import LegalTextForFooter from "components/forms/LegalTextForFooter";
import SubmitButton from "../forms/SubmitButton";
import FormTextField from "../forms/FormTextField";
import ModalShell from "./ModalShell";

function WaitingListModal() {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newsLetter, setNewsLetter] = useState(false);
  const [exist, setExist] = useState(false);
  const { open, setOpen, subscriptionType } = useContext(WaitingListContext);

  function closeModal() {
    setOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData(e.target);
    const url = "https://lvtgsm2egg.execute-api.eu-central-1.amazonaws.com/add";

    try {
      const result = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(Object.fromEntries(formData.entries())),
      });
      const data = await result.json();
      if (data === "exist") {
        setExist(true);
      }
      setSuccess(true);
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setSubmitting(false);
    }
  };

  const successMsg = (
    <div className="py-24 px-4 sm:px-6 lg:px-8 text-center">
      <Typography variant="h4">Thank you for applying.</Typography>
      <p>You are now on the ooofer waiting list.</p>
      <p>
        We will be in touch as soon as we are able to welcome you to the
        community.
      </p>
    </div>
  );

  const existingMsg = (
    <div className="py-24 px-4 sm:px-6 lg:px-8 text-center">
      <Typography variant="h4">Already registered</Typography>
      <p>This email address was already registered with us.</p>
    </div>
  );

  const formBody = (
    <>
      <Typography variant="h3" className="text-center mt-6 sm:mt-2">
        Join the waiting list
      </Typography>
      <form onSubmit={handleSubmit} className="mt-4 w-full">
        <div className="flex flex-col gap-4">
          <FormTextField label="First name" name="firstName" />
          <FormTextField label="Last name" name="lastName" />
          <FormTextField label="Email address" name="email" type="email" />
          <NewsletterSwitch
            newsLetter={newsLetter}
            setNewsLetter={setNewsLetter}
          />
          <FormTextField
            label="Newsletter"
            type="hidden"
            name="newsletter"
            value={newsLetter ? "Yes" : "Nope"}
          />
          <FormTextField
            label="Subscription"
            type="hidden"
            name="subscriptionType"
            value={subscriptionType}
          />
        </div>

        <SubmitButton
          title="Sing me up to the waiting list"
          submitting={submitting}
        />
        <LegalTextForFooter />
      </form>
    </>
  );

  return (
    <ModalShell open={open} closeModal={closeModal}>
      {success ? (exist ? existingMsg : successMsg) : formBody}
    </ModalShell>
  );
}

export default WaitingListModal;
