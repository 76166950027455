import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import { APPLE_STORE_URL, getMobileOS, PLAY_STORE_URL } from "../utils/detect-os";
import appstore from "../images/socialMedia/appstore.svg";
import playstore from "../images/socialMedia/playstore.svg";
import splitbee from '@splitbee/web';

export function DownloadButton(props) {
    const size = props.size || "md";
    const classNames = [size === 'lg' ? 'h-14' : size === 'sm' ? 'h-6 md:h-8' : 'h-12 md:h-12', props.className || ''];
    const mobileOs = getMobileOS();

    const iOSButton = <AnchorLink 
        as="a"
        to={APPLE_STORE_URL}
        background="white"
        onAnchorLinkClick={() => {
            splitbee.track('download-ios');
        }}
        >
        <img className={classNames.join(' ')} alt='Download on the App store' src={appstore}/>
    </AnchorLink>
    
    const androidButton = <AnchorLink
        as="a"
        to={PLAY_STORE_URL}
        background="white"
        onAnchorLinkClick={() => {
            splitbee.track('download-android');
        }}
    >
        <img className={classNames.join(' ')} alt='Get it on Google Play' src={playstore}/>
    </AnchorLink>
    if(mobileOs === "iOS") {
        return iOSButton;
    } else if(mobileOs === "Android") {
        return androidButton
    } else {
        return <div className="flex w-56 gap-2 md:w-96">
            {iOSButton}
            {androidButton}
        </div>
    }
}